import { Options } from "vue-class-component";
import { ValueType } from "../form/ComponentsConfigObject";
import OrgPicker from "../../components/OrgPicker/OrgPicker.vue";
import OrgTypePicker from "../../components/OrgTypePicker/OrgTypePicker.vue";
import DeptPicker from "../../components/DeptPicker/DeptPicker.vue";
import PostPicker from "../../components/PostPicker/PostPicker.vue";
import UserPicker from "../../components/UserPicker/UserPicker.vue";
import PickerItems from "../../components/PickerItems/PickerItems.vue";
import BaseFunction from "@/mixins/BaseFunction";

@Options({
    name: "flowable-nodes-config-mixins",
    components: {
        OrgPicker,
        OrgTypePicker,
        DeptPicker,
        PostPicker,
        UserPicker,
        PickerItems,
    },
    props: {
        config: {
            type: Object,
            default: (): any => {
                return {};
            },
        },
    },
})
export default class NodesConfigMixins extends BaseFunction {
    public config: any = {};

    //是否多选
    public orgMultiple = false;
    public deptMultiple = false;
    public postMultiple = false;
    public userMultiple = false;

    //按钮图标
    public orgIcon = "Plus";
    public deptIcon = "Plus";
    public postIcon = "Plus";
    public userIcon = "Plus";

    // public orgPickerSelected: any = [];
    // public deptPickerSelected: any = [];
    // public postPickerSelected: any = [];
    // public userPickerSelected: any = [];

    get ValueType() {
        return ValueType;
    }
    get groupNames() {
        return ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
    }
    get setup(): any {
        return this.$store.getters["flowable/design"];
    }
    set setup(val: any) {
        this.$store.dispatch("flowable/setDesign", val).then();
    }
    get nodeMap() {
        return this.$store.getters["flowable/nodeMap"];
    }
    set nodeMap(val: any) {
        this.$store.dispatch("flowable/setNodeMap", val).then();
    }
    get diagramMode(): any {
        return this.$store.getters["flowable/diagramMode"];
    }
    set diagramMode(val: any) {
        this.$store.dispatch("flowable/setDiagramMode", val).then();
    }
    get selectedNode() {
        return this.$store.getters["flowable/selectedNode"];
    }
    set selectedNode(val) {
        this.$store.dispatch("flowable/setSelectedNode").then();
    }
    get formItems() {
        return this.setup.formItems || [];
    }
    set formItems(val: any) {
        this.setup.formItems = val;
    }
    get nodeProps() {
        return this.selectedNode.props;
    }
    set nodeProps(val) {
        this.selectedNode.props = val;
    }

    //选择器计算属性
    get assignedOrg() {
        if (!this.config.assignedOrg) this.config.assignedOrg = [];
        return this.config.assignedOrg;
    }
    set assignedOrg(val: any) {
        this.config.assignedOrg = val;
    }
    get assignedDept() {
        if (!this.config.assignedDept) this.config.assignedDept = [];
        return this.config.assignedDept || [];
    }
    set assignedDept(val: any) {
        this.config.assignedDept = val;
    }
    get assignedPost() {
        if (!this.config.assignedPost) this.config.assignedPost = [];
        return this.config.assignedPost || [];
    }
    set assignedPost(val: any) {
        this.config.assignedPost = val;
    }
    get assignedUser() {
        if (!this.config.assignedUser) this.config.assignedUser = [];
        return this.config.assignedUser || [];
    }
    set assignedUser(val: any) {
        this.config.assignedUser = val;
    }

    //选择器节点删除
    public orgItemRemove(index: any) {
        this.assignedOrg.splice(index, 1);
    }
    public deptItemRemove(index: any) {
        this.assignedDept.splice(index, 1);
    }
    public postItemRemove(index: any) {
        this.assignedPost.splice(index, 1);
    }
    public userItemRemove(index: any) {
        this.assignedUser.splice(index, 1);
    }

    //选择器选中数据
    public orgSelected(select: any) {
        this.assignedOrg.length = 0;
        select.forEach((val: any) => this.assignedOrg.push(val));
    }
    public deptSelected(select: any) {
        this.assignedDept.length = 0;
        select.forEach((val: any) => this.assignedDept.push(val));
    }
    public postSelected(select: any) {
        this.assignedPost.length = 0;
        select.forEach((val: any) => this.assignedPost.push(val));
    }
    public userSelected(select: any) {
        this.assignedUser.length = 0;
        select.forEach((val: any) => this.assignedUser.push(val));
    }

    //打开选择器
    public orgPickerOpen(multiple: boolean) {
        this.orgMultiple = multiple;
        (this.$refs.orgPicker as any).modalOpen();
    }
    public deptPickerOpen(multiple: boolean) {
        this.deptMultiple = multiple;
        (this.$refs.deptPicker as any).modalOpen();
    }
    public postPickerOpen(multiple: boolean) {
        this.postMultiple = multiple;
        (this.$refs.postPicker as any).modalOpen();
    }
    public userPickerOpen(multiple: boolean) {
        this.userMultiple = multiple;
        (this.$refs.userPicker as any).modalOpen();
    }
}
